﻿@import 'variables';
@import 'mixins';

.ProdListContainer {
    @include bootstrapContainer();
    padding-left: 0;
    padding-right: 0;
}

.ColumnCenterWithSidebarLeft .ProdListContainer {
    width: 100%;
}

/* Sort by */

.FieldSorterBottom .sort-by-container,
.FieldSorter .search-paging {
    @include toSizeSM {
        display: none;
    }
}

.FieldSorterBottom .FieldSorter .search-paging {
    @include toSizeSM {
        display: block;
    }
}

.normal-mode .search-no-result {
    display: none;
}

.search-no-result {
    @include toSizeSM {
        margin: 0 15px;

        h3 {
            font-size: 16px;
            text-align: center;
        }
    }

    .ProduktListCellsPublisherContainer {
        width: 100%;
        margin-bottom: 10px;
    }
}

.FieldSorter.web-pub-field-sort {
    width: 100%;
    padding: 0 5px;
    text-align: center;

    @include fromSizeSM {
        display:block;
        text-align:left;
        padding: 0;
    }
}

attribute-sort-box.produkt-filter-attributter {
    display: none;

    @include fromSizeSM {
        display: block;
    }
}

.web-pubfield-sort .sort-by-label {
    display: none;
}

.FieldSorter select,
.attribute-sort-box select {
    width: 100% !important;
    border: none;
    text-indent: 10%;
    padding: 7px 0;
    font-size: 16px;
    background-color:transparent;

    @include toSizeSM {
        appearance: none;
    }

    @include fromSizeSM {
        padding: 3px 0 3px 5px;
        width: 130px !important;        
        margin-top: 0;
        border: 1px solid #cccccc;
        border-radius: 0px;
        text-indent: initial;
    }
}





/* Paging */
.FieldPaging {
    float: left;

    @include fromSizeSM() {
        float: none;
    }
}

.PrdListCellsPagingTop {
    display: none;

    @include fromSizeSM {
        display: block;
        float: left;
    }
}

.PrdListCellsPagingBottom {
    margin-bottom: 10px;
    /*margin-left: 10px;*/
}

.PrdListCellsPagingTop a,
.PrdListCellsPagingBottom a,
.FieldPaging a {
    background: #f5f5f5;
    color: #333;
    margin-right: 6px;
    margin-bottom: 10px;
    border-radius: 0px;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    width: 42px;
    height: 38px;
    padding: 10px;

    @include fromSizeSM {
        margin-right: 4px;
        margin-bottom: 0;
        padding: 4px 0;
        width: 28px;
        height: 28px;
    }

    &:hover {
        text-decoration: none;
        background: #2a2a2e;
        color: white;
    }
}

.FieldPaging a {
    margin-right: 9px;

    @include fromSizeSM {
        margin-right: 4px;
    }
}

.PrdListCellsPagingTop a.SelectedPagingIndex,
.PrdListCellsPagingBottom a.SelectedPagingIndex,
.FieldPaging a.SelectedPagingIndex {
    background: #2a2a2e;
    color: white;
}

.ProdListContainer .ProduktImg {
    margin: 0;

    img {
        max-width: 60px;
        height: auto;

        @include fromSizeSM() {
            max-width: 80px;
        }
    }
}

.TextBoxAntall {
    width: 42px;
    height: 30px;
    text-align: center;
    border: 1px solid #cccccc;
    border-radius: 0px;
}

.ProduktDesc a {
    text-decoration: none;
    color: $textColor;
}

.product-desc1 {
    font-weight: bold;
    font-size: 12px;

    @include fromSizeSM() {
        font-weight: normal;
        ;
        font-size: 16px;
    }
}

.product-desc2 {
    font-size: 12px;

    @include fromSizeSM() {
        font-size: 14px;
    }
}

.ProduktDesc,
.ProduktDesc span {
    line-height: 1em;

    @include fromSizeSM() {
        line-height: initial;
    }
}

.product-desc-prod-num-lbl,
.product-desc-stagtxt {
    color: $textMutedColor;
    font-size: 12px;
}

.product-desc-prod-num {
    font-weight: 300;
    color: $textMutedColor;
    font-size: 12px;
}

.ProduktListPris {
    font-weight: bold;
}

/**** Variants ****/
.variant-dropdowns {
    margin-top: 5px;

    .VariantSelectorAttribOne {
        margin-right: 10px;
    }

    .VariantSelectorAttribOne,
    .VariantSelectorAttribTwo {
        float: left;
    }
}

.ProduktlistAttributtVerdi1Txt,
.product-desc-stagtxt,
.product-desc-news {
    display: none;
}


/* Hide cells form mobile */
.ProduktListVeilPris,
.ProduktListPercent,
.prod-list-quantity-cell, 
.prod-list-buy-cell,
.ProdListContainer .variant-dropdowns {
    @include toSizeSM() {
        display: none;
    }
}

.list-price {       
    white-space: nowrap;    
}
.list-price-content {    
    font-size: 12px;
    text-align: right;    
    font-weight:bold;
    @include fromSizeSM() {
        font-size: 16px;
    }
}

/* Favorites */

.btn.btn-primary.has-favorite-btn {
    border-radius: 0px;
    
    @include fromSizeSM() {
        padding: 6px 18px;
    }

    @include fromSizeLG() {
        border-radius: 0px;
        padding: 6px 12px;
    }
}


.favorite-list-btn .heart-full {
    display: none;
}

.btn.btn-primary.list-buy-button.has-favorite-btn {
    @include toSizeSM() {
        display:none;
    }

    @include fromSizeLG() {
        border-radius: 0px;
    }
}

.btn.favorite-list-btn {
    margin-top: 6px;
    padding: 2px 6px 0px 6px;
    border-radius: 0px;
    font-size: 21px;
    display: block;

    @include fromSizeSM() {
        padding: 2px 21px 0px 22px;
    }

    @include fromSizeLG() {
        display:inline-block;
        margin-top: 0;
        margin-left: -2px;
        border-radius: 0px;
        padding: 2px 0 0 0;
        width: 35px;

        &.hide-buy {
            border-radius: 0px;
        }
        
    }

    &.list-fav-config{
        @include fromSizeSM() {
            padding: 2px 30px 0px 32px;
        }
        @include fromSizeLG() {
            padding: 2px 0 0 0;
        }
    }    
}

body.layoutver4 div.AddProductImage img{
    max-height:380px;
    width:auto;
    max-width: 100%;
}
.product-sorter{
    float:right;
}

/*D4 standard list template*/
.d4-listing-row .DynamicStockTooltipContainer {
    display: inline;
    font-size: 12px;
}

.d4-listing-row .StockIcon {
    margin-right: 5px;
    vertical-align: text-bottom;
}

.d4-listing-row .ProduktImg {
    display: inline-block;
}

body.show-ad-checkboxes .ajax-field[data-field-is-productlist='1'] .d4-listing-row .check-favorite {
    display: inline-block;
    position: static;
    margin: 0;
    margin-right: 10px;
}

.WebPubElement .D4Standard .AddProductImage > a{
    display:inline-block;
    width:100%;
    height:100%;
}