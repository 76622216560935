﻿@import 'variables';
@import 'mixins';

.InstantSearch .d4-instant-search-preview-template{
    width:100%;
}
.InstantSearch .d4-instant-search-preview-template .d4-instant-search-preview-top {
    display: grid;
    grid-template-columns: auto 80px;
    grid-gap: 10px;
}

.InstantSearch .d4-instant-search-preview-template .d4-instant-search-preview-bottom {
    display: grid;
    grid-template-columns: auto 160px;
    grid-gap: 20px;
    padding-top:10px;
}

.d4-instant-search-preview-bottom .d4-instant-search-preview-info {
    max-height: 300px;
    text-overflow: ellipsis;
    .prod-text-content {
        overflow: hidden;
        img, iframe {
            max-width: 100%;
        }
    }
}
.d4-instant-search-preview-bottom .d4-instant-search-preview-image {
    position: relative;
    .YouSavePercentLabel {
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 1;
        font-weight: bold;
        background: $youSaveBackground;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: none;
        font-size: 10px;
        text-align: center;
        padding-top: 7px;
    }
}

.InstantSearch h3 {
    font-size: 16px;
    font-weight: bold;
}

.InstantSearch .d4-instant-search-preview-header1 {
    font-size: 22px;
    font-weight: bold;
    color: $headingColor;
}
.InstantSearch .d4-instant-search-preview-header2 {
    font-size: 14px;
    font-weight: bold;
    color: $headingColor;
    display:block;
}
.d4-instant-search-preview-price {
    font-size: 14px;
    font-weight: bold;
    color: $priceColor;
    text-align:right;
}

.d4-instant-search-divider {
    padding-bottom: 8px;
    border-bottom: solid 1px $borderColor;
}

.d4-instant-search-preview-heading{
    min-height:40px;
}